import React from 'react';
import { 
  Grid, 
  Card, 
  CardMedia, 
  CardContent, 
  Typography, 
  Button, 
  Box,
  IconButton,
  Chip,
  Link
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useAddProductToCart } from 'shared/react-query/hooks';
import { useCheckoutId } from '~/hooks/uselocalForage';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '1920px',
    margin: '0 auto',
    padding: theme.spacing(2),
  },
  gridContainer: {
    justifyContent: 'center',
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  card: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    width: '100%',
    maxWidth: '536px',
    height: '100%',
  },
  media: { 
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
  },
  content: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    padding: theme.spacing(2),
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: 'black',
    maxWidth: '536px',
    [theme.breakpoints.down(['md', 'sm'])]: { 
      top: '50%',
      padding: theme.spacing(1.5),
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1rem',
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.up('md')]: {
      fontSize: '1.25rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
    },
  },
  description: {
    fontSize: '1rem',
    color: 'grey',
    marginBottom: theme.spacing(0.5),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    [theme.breakpoints.up('lg')]: {
      WebkitLineClamp: 'unset', 
      overflow: 'visible',      
      textOverflow: 'unset',
      display: 'block',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '0.9rem',
      WebkitLineClamp: 2,      
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
      WebkitLineClamp: 2,        
    },
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '-15px',
  },
  price: {
    fontWeight: 'bold',
  },
  orderButton: {
    textTransform: 'none',
    borderRadius: '20px',
    padding: '2px 10px',
    boxSizing: 'border-box',
    border: '1px solid #8C8C8C',
    color: 'black',
    fontWeight: 'normal',
  },
  quantityControl: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  quantityButton: {
    padding: 0,
  },
  quantity: {
    margin: '0 10px',
  },
  healthCreator: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    color: 'white',
    padding: '2px 8px',
    borderRadius: '15px',
    fontSize: '0.7rem',
    display: 'flex',
    alignItems: 'center',
  },
  moreInfo: {
    fontSize: '0.8rem',
    color: theme.palette.primary.main,
    textAlign: 'right',
    display: 'block',
    marginTop: theme.spacing(1),
  },
  productInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '0.75rem',
    color: 'grey',
    marginTop: theme.spacing(1),
  },
  info: {
    fontSize: '1rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '0.9rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    },
  }
}));

const ImageListComponent = ({ section }) => {
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation(); 
  const adjustedPathname = pathname.endsWith('/') ? pathname : `${pathname}/`;
  const {checkoutId} = useCheckoutId();
  const addItemToCartMutation = useAddProductToCart();

  const handleImageClick = (handle) => {
    history.push(`${adjustedPathname}${handle}`);
  };

  return (
    <Grid container spacing={2} wrap="wrap" className={classes.root}>
      <Grid container spacing={2} className={classes.gridContainer}>
        {section?.map(({node}, index) => (
      <Grid item xs={12} sm={12} md={12} lg={4} key={node.id} className={classes.gridItem} style={{ width: '90%' }}>
        <Card className={classes.card}>
          <CardMedia
            component="img"
            className={classes.media}
            image={node.variants.nodes?.[0]?.image?.src}
            alt={node.title}
            onClick={() => handleImageClick(node.handle)}
            style={{ cursor: 'pointer' }}   
          />
          <Chip
            icon={<FavoriteIcon style={{ color: 'white' }} />}
            label="Add to health creator"
            className={classes.healthCreator}
            size="small"
          />
          <CardContent className={classes.content}>
              <Typography variant="h6" className={classes.title}>{node.title}</Typography>
              {/* <Typography variant="h6" className={classes.title}>{item.name}</Typography> */}
            {/* <Typography variant="body2" className={classes.description}>{item.desc}</Typography>
            <Box className={classes.productInfo}>
              <Typography className={classes.info}><strong>Ingredients:</strong>{item.ingredients}</Typography>
              <Typography className={classes.info}><strong>Capacity:</strong> {item.capacity}</Typography>
              <Typography className={classes.info}><strong>Type:</strong> {item.type}</Typography>
            </Box> */}
            <Box className={classes.details}>
              <Typography variant="body1" className={classes.price}>
                  ${node.variants.nodes?.[0]?.priceV2?.amount}
              </Typography>
              <Button 
                size="small" 
                variant="outlined" 
                color="primary" 
                className={classes.orderButton}
                onClick={() => {
                  addItemToCartMutation.mutate({
                      cartId: checkoutId,
                      lineItems: {merchandiseId: node.variants.nodes?.[0]?.id, quantity: 1},
                  });
                }}
              >
                Order & pick up
              </Button>
            </Box>
            {/* <Link href="#" className={classes.moreInfo}>
              more info
            </Link> */}
          </CardContent>
        </Card>
        </Grid>
         ))}
      </Grid>
    </Grid>
  );
};

export default ImageListComponent;