import React, { useState } from 'react';
import './index.css';
import playerIcon from '../../assets/images/page/account/pro-icon.png';
import home from '../../assets/images/page/account/home.png';
import star from '../../assets/images/page/account/star.png';
import { Grid } from '@material-ui/core';
import { useAuth } from 'shared/auth';
import { useQuery } from 'react-query';
import { fetchVisionaireProfile } from 'shared/react-query/strapiAPI';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useFetchOrderHistory } from 'shared/react-query/hooks';
import { isNil } from 'lodash';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { StrapiVideo } from "shared/ui/StrapiVideo"
import { getStrapiAssetURL } from "shared/utils/strapiUtils";
import { PlayCircleOutline } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import AccountTabsContent from "shared/sections/account/AccountTabsContent";

const Account = () => {
  const { id: slug } = useParams();
  const [playVideo, setPlayVideo] = useState(false);
  const { auth, profileData } = useAuth();
  const { data, error, isLoading } = useQuery(['visionaireProfile', slug, auth.token], () => fetchVisionaireProfile(slug ?? profileData?.slug, auth.token), { enabled: !isNil(slug ?? profileData?.slug) });

  const { data: orderHistoryData } = useFetchOrderHistory({ token: auth.token })
  console.log(orderHistoryData, 'orderHistoryData')
  if (!data) {
    return <></>
  }
  const images = data?.data.attributes?.displayPhotosAndVideos?.data?.map((e, i) => {
    if (e?.attributes?.mime == "video/mp4") {
      return <div style={{ position: "relative", width: "100%", height: "100%" }} onClick={() => { setPlayVideo(e?.attributes?.url) }}> <StrapiVideo segment={e?.attributes?.url} controls={false} />
        <PlayCircleOutline style={{ position: "absolute", bottom: "35%", left: "50%", transform: "translateX(-50%)", height: "60px", width: "60px" }} />
      </div>
    }
    return (
      <img key={i} src={getStrapiAssetURL(e?.attributes?.url)} className="dynamicimg" />
    )
  })
  return (
    <Grid className="account-detail">
      <Grid className='profile-wrap'>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item md={6}>
            <div className="profile-sec">
              <div className='pro'>
                <Carousel showArrows={true} showThumbs={false}>
                  {images}
                </Carousel>
                <div className='btn-pro'>
                  <button>UPLOAD Image</button>
                  <button>SET DEFAULT</button>
                </div>
              </div>
              <div className='pro2'>
                <Typography className="userName">{data.data.attributes.firstName} {data.data.attributes?.lastName ?? ""}</Typography>
                <p> <img alt="not found" src={home} />{data.data.attributes.location}</p>
                <p> <img alt="not found" src={star} />{data.data.attributes.title}</p>
                {/* <button>MY SCREEN SAVER</button> */}
              </div>

            </div>
          </Grid>
          <Grid item md={2}>
            <div className="profile-sec2">
              <p>METAWHEEL</p>
              <img alt="not found" src={playerIcon} />
            </div>
          </Grid>
          <Grid item md={4}>
            <div className="profile-sec3">
              <button>Progress account profile</button>
              <button>set public profile</button>
            </div>
          </Grid>
        </Grid>
        <AccountTabsContent />
      </Grid>
    </Grid>
  );
}


export default Account